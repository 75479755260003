import Transition from "../Components/Features/Transition";
import AnimatedWordChange from '../Components/Features/AnimatedWord';
import AnimatedSlogan from "../Components/Common/AnimatedSlogan";
import AnimatedImages from "../Components/Common/AnimatedImages";

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen bg-main-bg">
      <AnimatedWordChange words={["bananas", "dream", "product", "website", "ads"]} interval={2700}/>
      <div className="">
          <AnimatedSlogan />
      </div>
    </div>
  );
};

export default Transition(Home, "Welcome\nIn The\nJungle");