import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Navbar from './Components/Layout/Navbar';
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import Projects from './Pages/Projects';
import Socials from './Pages/Socials';
import About from './Pages/About';

function App() {
  const location = useLocation();

  return (
    <>
      <AnimatePresence mode="wait">
        <div key="main" className="flex flex-col min-h-screen font-bebas bg-main-bg">
          <Navbar />
          <Routes location={location} key={location.pathname}>
            <Route index element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="projects" element={<Projects />} />
            <Route path="socials" element={<Socials />} />
            <Route path="about" element={<About />} />
          </Routes>
        </div>
      </AnimatePresence>
    </>
  );
}

export default App;