import React from "react";
import Transition from "../Components/Features/Transition";

const Social = () => {
    return (
        <div>
            <h1>Social</h1>
        </div>
    )
}

export default Transition(Social, "Join Our Kong's \nTroop");