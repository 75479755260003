import React from "react";
import Transition from "../Components/Features/Transition";

const About = () => {
    return ( 
        <div>
            About
        </div>
    )
}

export default Transition(About, "Discover\nOur Troop");