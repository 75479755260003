import React, { useRef } from 'react';
import { motion, useInView, useAnimationControls } from 'framer-motion';

const AnimatedSlogan = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.5 });
  const controls = useAnimationControls();

  React.useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  const containerVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const wordVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  const letterVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 200
      }
    }
  };

  const AnimatedText = ({ text, className = "" }) => (
    <motion.span className={className} variants={wordVariants}>
      {text.split(" ").map((word, wordIndex) => (
        <React.Fragment key={wordIndex}>
          {word.split("").map((char, charIndex) => (
            <motion.span
              key={`${wordIndex}-${charIndex}`}
              variants={letterVariants}
              style={{ display: 'inline-block' }}
            >
              {char}
            </motion.span>
          ))}
          {wordIndex < text.split(" ").length - 1 && (
            <span style={{ display: 'inline-block', width: '0.3em' }}></span>
          )}
        </React.Fragment>
      ))}
    </motion.span>
  );

  return (
    <motion.div
      ref={ref}
      className="h-[50vh] flex flex-col text-guisol text-7xl text-end w-full"
      variants={containerVariants}
      initial="hidden"
      animate={controls}
    >
      <AnimatedText text="Elevating Your Ideas Through" />
      <AnimatedText text="Our Creativity" className="text-comp" />
    </motion.div>
  );
};

export default AnimatedSlogan;