import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Transition = (OgComponent, sectionText) => {
  return function WithTransition(props) {
    const [showTransition, setShowTransition] = useState(false);
    const [showNextPage, setShowNextPage] = useState(false);

    useEffect(() => {
      const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
      if (hasVisitedBefore) {
        setShowTransition(true);
        // Delay showing the next page until after the transition
        setTimeout(() => setShowNextPage(true), 3000); // Adjust time to match your transition duration
      } else {
        localStorage.setItem('hasVisitedBefore', 'true');
        setShowNextPage(true); // Immediately show the page on first visit
      }
    }, []);

    return (
      <>
        <AnimatePresence mode="wait">
          {showTransition && (
            <motion.div
              key="transition"
              className="fixed inset-0 z-30 pointer-events-none"
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{
                '--primary-color': 'black',
                '--secondary-color': 'white',
                '--animation-duration': '1.5s',
              }}
            >
              <LeftTransition />
              <RightTransition sectionText={sectionText} />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {showNextPage && (
            <motion.div
              key="page"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <OgComponent {...props} />
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };
};

const LeftTransition = () => (
  <motion.div
    className="absolute top-0 left-0 w-1/2 h-full flex items-center justify-center"
    style={{ backgroundColor: 'var(--primary-color)' }}
    initial={{ x: '-100%' }}
    animate={{ x: ['-100%', '0%', '-100%'] }}
    transition={{
      duration: 3,
      times: [0, 0.5, 1],
      ease: [0.6, 0.01, -0.05, 0.9],
    }}
  >
    <motion.div
      className="w-full h-full relative"
      initial={{ opacity: 0 }}
      animate={{ opacity: [0, 1, 0] }}
      transition={{
        duration: 3,
        times: [0, 0.7, 1],
        ease: "easeInOut"
      }}
    >
      <img
        src="/akakong-logo-alpha-white.svg"
        alt="logo"
        className="w-1/3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      />
    </motion.div>
    <motion.h2
      className="text-4xl absolute flex flex-col right-0"
      style={{ color: 'var(--secondary-color)' }}
      initial={{ opacity: 0, y: 20, rotate: -90 }}
      animate={{ opacity: [0, 1, 0], y: [20, 0, 20] }}
      transition={{
        duration: 4.5,
        times: [0, 0.25, 1],
        ease: "easeInOut"
      }}
    >
      <div className='font-strippy'>AKAKONG</div>
      <div className='text-[0.75rem] text-end font-strippy -mt-5 -mb-20'>Studio</div>
    </motion.h2>
  </motion.div>
);

const RightTransition = ({ sectionText }) => {
  const renderText = () => {
    if (React.isValidElement(sectionText)) {
      return sectionText;
    }
    if (typeof sectionText === 'string') {
      const lines = sectionText.split('\n');
      return lines.map((line, index) => (
        <div
          className={`text-left font-carpenter ${index === lines.length - 1 ? 'text-inv' : ''}`}
          style={{ marginLeft: `${index * 60}px` }}
          key={index}
        >
          {line}
        </div>
      ));
    }
    return null;
  };

  return (
    <motion.div
      className="absolute top-0 right-0 w-1/2 h-full flex flex-col items-center justify-center"
      style={{ backgroundColor: 'var(--primary-color)' }}
      initial={{ x: '100%' }}
      animate={{ x: ['100%', '0%', '100%'] }}
      transition={{
        duration: 3,
        times: [0, 0.5, 1],
        ease: [0.6, 0.01, -0.05, 0.9],
      }}
    >
      <motion.div
        className="text-8xl absolute left-20 font-bebas font-extrabold mb-4"
        style={{ color: 'var(--secondary-color)' }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: [0, 1, 0], y: [20, 0, 20] }}
        transition={{
          duration: 3,
          times: [0, 0.7, 1],
          ease: "easeOut"
        }}
      >
        {renderText()}
      </motion.div>
    </motion.div>
  );
};

export default Transition;