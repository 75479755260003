import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const AnimatedWordChange = ({ words = ["banana", "products", "ads", "websites"], interval = 2000 }) => {
  const [currentWord, setCurrentWord] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWord((prev) => (prev + 1) % words.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [words, interval]);

  return (
    <div className="flex flex-col justify-center font-moai text-8xl h-screen pl-8">
      <div className="">
        From Concept
      </div>
      <div className="flex items-center ">
        <span className="mr-4">To</span>
        <div className="h-24 inline-block text-comp">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentWord}
              initial={{ y: 0, opacity: 0.3 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 80, opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              {words[currentWord]}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default AnimatedWordChange;

// Usage example:
// import AnimatedWordChange from './path/to/AnimatedWordChange';
// 
// const Home = () => {
//   return (
//     <div className="flex flex-col min-h-screen bg-main-bg">
//       <AnimatedWordChange words={["custom", "words", "here"]} interval={3000} />
//     </div>
//   );
// };
// 
// export default Transition(Home, "Welcome\nIn The\nJungle");